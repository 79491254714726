export const COUNTRY_CODE_COOKIE = "country-code" as const;

export const getCookie = (key: string) => {
  if (typeof document === "undefined") {
    return "";
  }
  return document.cookie?.split("; ").find((cookie) => cookie.startsWith(key));
};

export const getCookieValue = (key: string) => getCookie(key)?.split("=")[1];

export const getCountryCodeCookie = () => getCookieValue(COUNTRY_CODE_COOKIE);
