import { Provider as AccordionSideNavProvider } from "@10xdev/ui/src/Support/SideNav/AccordionSideNavContext";
import { init as initFullStory } from "@fullstory/browser";
import App from "next/app";
import Script from "next/script";

import { checkConsentCookie } from "../utils/cookies";
import { checkIfInSampleGroup } from "../utils/check-fullstory-sample-group";

class Site extends App<object> {
  componentDidMount() {
    const hasAcceptedCookies =
      checkConsentCookie("consent") && checkConsentCookie("analytics");
    const isInSampleGroup = checkIfInSampleGroup();

    if (
      process.env.NODE_ENV === "production" &&
      hasAcceptedCookies &&
      isInSampleGroup
    ) {
      initFullStory({
        orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || "",
      });
    }
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <AccordionSideNavProvider>
        {/* Google Tag Manager: https://developers.google.com/tag-manager/quickstart */}
        {process.env.NODE_ENV === "production" && (
          <Script id={"google-analytics"} strategy={"afterInteractive"}>
            {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');
            `}
          </Script>
        )}
        <Component {...pageProps} />
      </AccordionSideNavProvider>
    );
  }
}

export default Site;
